<template>
  <div class="socket"></div>
</template>

<script>
export default {
  name: 'socket',
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped></style>
