<template>
  <div class="communicate" @click="contentSet(false)">
    <header class="communicate-header">
      <div class="title">沟通</div>
      <!-- <div class="communicate-remind" @click="$store.commit('set', { type: 'remind', data: true })">
        <div class="remind-icon">
          <img src="../../../assets/image/icon-progress/icon_tx.png" />
        </div>
        <span>提醒</span>
      </div> -->
    </header>
    <main class="communicate-main">
      <div class="left-nav">
        <div class="search">
          <input
            type="tel"
            placeholder="搜索成员..."
            v-model="memberKeyword"
            @input="memberSearch"
            @keyup.enter="memberSearch"
          />
          <div class="add-ico" @click="showCreateGroup" />
        </div>
        <ul class="chat-list" v-if="chatList[0].is !== ''">
          <li
            v-for="(item, index) in chatList"
            :key="item.groupid"
            :class="{ chatSelect: chatSelect === index }"
            @click="chatListClick(index)"
          >
            <div class="head-draw head" v-if="item.is === 'chatgroups'">群组</div>
            <!-- <div class="head-draw head" v-else-if="item.is === 'users'">{{ lastName(item.name) }}</div> -->
            <img class="head-draw head" v-if="item.is === 'users' && item.avatar != defaultImg" :src="item.avatar" :alt="lastName(item.name)" />
            <div class="head-draw head" v-else-if="item.is === 'users' && item.avatar == defaultImg">{{lastName(item.name)}}</div>
            <div class="chat-detail">
              <span class="project">{{ item.name }}</span>
              <span class="project" v-if="item.is === 'chatgroups'">({{ item.count }})</span><br />
              <span
                class="last-message"
                v-show="groupMsg(item.id, item.is)"
                v-if="groupMsg(item.id, item.is).type === ''"
                v-html="groupMsg(item.id, item.is).msg"
              ></span>
              <span
                class="last-message"
                v-show="groupMsg(item.id, item.is)"
                v-else-if="groupMsg(item.id, item.is).type === 'img'"
                >[图片]</span
              >
              <span
                class="last-message"
                v-show="groupMsg(item.id, item.is)"
                v-else-if="groupMsg(item.id, item.is).type === 'file'"
                >[文件]</span
              >
              <span class="last-message" v-show="groupMsg(item.groupid)" v-else
                >[{{ groupMsg(item.groupid).type }}]</span
              >
            </div>
            <div class="message-time" v-if="$refs.message">
              {{ hmTime($refs.message.renderTime(groupMsg(item.id, item.is).time)) }}
            </div>
          </li>
        </ul>
      </div>
      <div class="communicate-frame">
        <header>
          <span v-if="chatList.length !== 0">{{ chatList[chatSelect].name }}</span>
          <div @click="showRightSidebar" class="img-box">
            <img src="../../../assets/image/icon-communicate/more@2x.png" width="18px" />
          </div>
        </header>
        <main>
          <div class="message-box" v-if="chatList[chatSelect].is !== ''">
            <message ref="message" :id="chatList[chatSelect].id" :type="chatList[chatSelect].is" />
          </div>
        </main>
        <footer>
          <div
            class="cropper-block"
            v-if="showCropper"
            @contextmenu.prevent="cropFinish(1)"
            @dblclick.prevent="cropFinish(0)"
          >
            <vueCropper
              style="background-size: cover"
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              :fixedBox="option.fixedBox"
              @realTime="realTime"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              @dblclick.self="$refs.cropper.stopCrop()"
            ></vueCropper>
          </div>
          <div class="picker-block" v-show="showPicker">
            <picker
              set="apple"
              :include="['people']"
              :showSearch="false"
              :showPreview="false"
              :showCategories="false"
              @select="addEmoji"
            />
            <emoji :emoji="emojiSelect" set="apple" :size="21" ref="emoji" style="opacity: 0" />
          </div>
          <div class="text-nav">
            <div class="icon bg" @click="showPicker = !showPicker" />
            <div class="icon wj" @click="$refs.communiup.click()" />
            <div class="icon jq" @click.prevent.self="cropperdisplay" />
            <!--            <div class="icon bg"/>-->
          </div>
          <pre
            class="textarea"
            ref="content"
            id="con"
            v-html="content"
            contenteditable="true"
            @keyup.enter.exact="sent"
            @keyup.ctrl.enter="lineFeed"
            @click="ku"
            @focus="preFocus"
          ></pre>
          <input v-show="false" type="file" ref="communiup" @change="changeFile($event)" />
        </footer>
      </div>
      <div class="right-sidebar friend" v-show="showRight" v-if="chatList[chatSelect].is === 'users'">
        <ul>
          <li @click="(showAddGroup = true), (friend = chatList[chatSelect].id)">
            <div class="add-ico" />
            <div class="add">添加</div>
          </li>
          <li>
            <div class="head-draw head">
              {{ lastName(chatList[chatSelect].name) }}
            </div>
            <div class="title">
              {{ lastName(chatList[chatSelect].name) }}
            </div>
          </li>
        </ul>
      </div>
      <div class="right-sidebar group" v-show="showRight" v-if="chatList[chatSelect].is === 'chatgroups'">
        <ul>
          <li @click="(showAddGroup = true), (friend = groupmember)">
            <div class="add-ico" />
            <div class="add">添加</div>
          </li>
          <li v-for="item in groupmember" :key="item.id">
            <div class="head-draw head">{{ lastName(item.nickname) }}</div>
            <div class="title">{{ lastName(item.nickname) }}</div>
          </li>
        </ul>
        <div class="name">
          <div class="str1">群聊名称</div>
          <div class="str2" @click="showModName">
            <el-input
              ref="groupnamemod"
              v-model="newGroupName"
              placeholder="按回车键结束"
              @keyup.enter.native="modGroupName()"
              @blur="groupnamemodBlur"
              @click.stop.native=""
              v-show="modNameShow"
            />
            <span v-show="!modNameShow">{{ chatList[chatSelect].name }}</span>
            <img
              src="../../../assets/image/icon-communicate/icon_pencil@2x.png"
              alt=""
              width="12px"
              v-show="!modNameShow"
            />
          </div>
        </div>
        <div class="delGroup" @click="delGroup" v-if="boss === name">解散群组</div>
      </div>
    </main>
    <add-group
      @groupClose="groupClose"
      v-if="showAddGroup"
      :id="chatList[chatSelect].id"
      :friend="friend"
      :groupname="chatList[chatSelect].name"
    />
    <socket></socket>
  </div>
</template>

<script>
import addGroup from '@/components/chat/communicate/addGroup';
import message from '@/components/chat/communicate/message';
import { Emoji, Picker } from 'emoji-mart-vue'; //引入表情组件
import vueCropper from 'vue-cropper';
import html2canvas from 'html2canvas';
import socket from '@/components/chat/communicate/socket';
import cookie from '@/utils/cookie';
import { postChatwsGroupDel, postChatwsGroupUserLis, postChatwsMsSend } from '@/service/help';
import { dataURLtoBlob, blobToFile, typeFile } from '@/utils/utils';
import { mapActions } from 'vuex';
import moment from 'moment';
import { Message } from 'element-ui';

export default {
  name: 'Communicate',
  data() {
    return {
      boss: '', //群主
      name: '',
      memberKeyword: '',
      chatList: [
        {
          is: '', //users或者chatgroups,用于判断是群聊还是单聊
          id: '',
          name: '',
          avatar: '', //头像
        },
      ],
      lastMsg: [],
      chatSelect: 0,
      showRight: true,
      content: '',
      showPicker: false, //表情选择器组件
      showCropper: false, //截图组件
      option: {
        /*        img: require("@/assets/image/timg.jpg"), // 裁剪图片的地址  (默认：空)*/
        img: '', // 裁剪图片的地址  (默认：空)
        size: 1, // 裁剪生成图片的质量  (默认:1)
        full: true, // 是否输出原图比例的截图 选true生成的图片会非常大  (默认:false)
        outputType: 'png', // 裁剪生成图片的格式  (默认:jpg)
        canMove: true, // 上传图片是否可以移动  (默认:true)
        original: false, // 上传图片按照原始比例渲染  (默认:false)
        canMoveBox: true, // 截图框能否拖动  (默认:true)
        autoCrop: false, // 是否默认生成截图框  (默认:false)
        autoCropWidth: 200, // 默认生成截图框宽度  (默认:80%)
        autoCropHeight: 200, // 默认生成截图框高度  (默认:80%)
        fixedBox: false, // 固定截图框大小 不允许改变  (默认:false)
        fixed: false, // 是否开启截图框宽高固定比例  (默认:true)
        fixedNumber: [1, 1], // 截图框比例  (默认:[1:1])
      },
      previews: {},
      showAddGroup: false,
      friend: 0,
      f: {
        url: '',
        filename: 'image.png',
        filetype: 'png',
        data: File,
      },
      modNameShow: false,
      newGroupName: '',
      groupmember: [], //群成员
      emojiSelect: {
        id: 'grinning',
        skin: null,
      },
      notifyPromise: Promise.resolve(),
      defaultImg:'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png',
    };
  },
  components: {
    Emoji,
    Picker,
    vueCropper,
    addGroup,
    message,
    socket,
  },
  created() {
    this.name = cookie(document).get('name');
    this.getChatList(true); //获取聊天列表的群名和id，该方法异步执行后才有id
  },
  methods: {
    ...mapActions([
      'sendImgMessage',
      'sendFileMessage',
      'onAgreeInviteGroup',
      'onGetSendMsLis',
      'getHistoryMessage',
      'onUpdataGroupInfo',
    ]),
    getChatList(isGetHistory = false) {
      //获取聊天列表
      let obj = { isGetHistory };
      if (isGetHistory) {
        this.chatSelect = 0;
      }
      this.onGetSendMsLis(obj); //vuex里面异步方法，
    },
    showCreateGroup() {
      this.showAddGroup = true;
      this.friend = cookie(document).get('uid');
    },
    groupClose() {
      //关闭群组组件的方法
      this.showAddGroup = false;
    },
    contentSet(b) {
      //算是非手动输入的内容
      this.content = this.$refs.content.innerHTML;
      if (b) {
        //判断是否将光标移动最后
        setTimeout(() => {
          this.keepLastIndex(this.$refs.content);
        }, 5);
      }
    },
    AgreeJoinGroup() {
      //同意加入群聊
      this.$store.dispatch('onAgreeInviteGroup', {
        username: JSON.parse(localStorage.getItem('userInfo')).userId,
        inviteGroupId: this.$store.state.group.groupInviteNotifications.gid,
        callback: this.getChatList,
      });
    },
    chatListClick(index) {
      //聊天列表点击方法
      this.chatSelect = index;
      let status = this.$store.state.group.groupStatus;
      if (this.chatList.length > 0) {
        if (this.chatList[index].is === 'chatgroups' && status === '') {
          this.getGroupUsers(index);
          this.newGroupName = this.chatList[index].name;
        }
      }
    },
    lastName(name) {
      if (name) return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    memberSearch() {
      // this.$store.state.chat.userList.sentMsLis
      this.chatListClick(0);
      this.chatList = this.$store.state.chat.userList.sentMsLis.filter(v => {
        return v.name.indexOf(this.memberKeyword) >= 0;
      });
    },
    showRightSidebar() {
      //打开侧边栏
      if (this.chatList[this.chatSelect].is !== '') {
        this.showRight = !this.showRight;
      } else
        this.$alert('右边什么都没有，需要创建一个聊天', '(;´༎ຶٹ༎ຶ`)打不开打不开', {
          confirmButtonText: '确定',
          center: true,
        });
    },
    addEmoji(e) {
      //添加表情
      //添加表情的方法
      this.emojiSelect = e;
      setTimeout(() => {
        let x = this.$refs.emoji.$el.children[0].style.backgroundPositionX;
        let y = this.$refs.emoji.$el.children[0].style.backgroundPositionY;
        this.content += `<span contenteditable="false" style="display: inline-block;width: 21px;height: 21px;vertical-align: middle;background-image: url(https://unpkg.com/emoji-datasource-apple@4.0.4/img/apple/sheets-256/64.png);background-size: 5200%;background-position: ${x} ${y};"/>`;
        setTimeout(() => {
          this.keepLastIndex(this.$refs.content);
        }, 5);
      }, 50);
      this.showPicker = false;
    },
    keepLastIndex(obj) {
      //将光标移到最后的方法
      if (window.getSelection) {
        //ie11 10 9 ff safari
        obj.focus(); //解决ff不获取焦点无法定位问题
        let range = window.getSelection(); //创建range
        range.selectAllChildren(obj); //range 选择obj下所有子内容
        range.collapseToEnd(); //光标移至最后
      } else if (document.selection) {
        //ie10 9 8 7 6 5
        let range = document.selection.createRange(); //创建选择对象
        range.moveToElementText(obj); //range定位到obj
        range.collapse(false); //光标移至最后
        range.select();
      }
    },
    changeFile(e) {
      console.log(e);
      let a = e.target.value;
      let name = a.slice(a.lastIndexOf('\\') + 1);
      let selectFile = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(selectFile);
      let _this = this;
      reader.onload = function (e) {
        let blob = dataURLtoBlob(e.target.result); //转blob
        if (blob.size > 314572800) {
          alert('所传文件不能大于300M');
          return false;
        }
        let f = new File([blob], name, { type: blob.type });
        let file = {};
        file.filename = name;
        file.url = '';
        file.data = f;
        file.filetype = blob.type;
        //选择上传文件发送
        if (!f.name) {
          _this.$refs.communiup.value = null;
          return false;
        }
        if (typeFile(file.filename) === 0) {
          //是图片的情况发送图片消息
          _this.sentImg(file);
        }
        if (typeFile(file.filename) !== 0) {
          //不是图片的情况发送文件消息
          _this.sentFile(file);
        }
      };
    },
    cropperdisplay() {
      this.getBodyResult(); //获取body节点的图片并赋值给cropper的img选项
      this.showCropper = true; //显示截图组件
      setTimeout(() => {
        //需要延迟触发，不然方法未定义
        this.$refs.cropper.goAutoCrop(); //自动生成裁剪框
        this.$refs.cropper.startCrop(); //开始截图
      }, 200);
    },
    realTime(data) {
      // 取得截图预览
      this.previews = data;
    },
    getBodyResult() {
      //获得body这个dom的截图
      let canvasID = document.body;
      let that = this;
      html2canvas(canvasID).then(canvas => {
        let dom = document.body.appendChild(canvas);
        dom.style.display = 'block';
        that.option.img = dom.toDataURL('image/png');
        document.body.removeChild(dom);
      });
    },
    cropFinish(n) {
      //截图完成方法
      this.$refs.cropper.stopCrop();
      let img = '';
      this.$refs.cropper.getCropData(n => {
        img = `<img width="100px" src=${n} alt=''>`;
      });
      if (n === 0) {
        setTimeout(() => {
          this.content += img;
        }, 100);
        this.showCropper = false;
      } else if (n === 1) {
        let c = confirm('是否退出截图？(双击可确认截图)');
        if (c) {
          this.showCropper = false;
        } else if (!c) {
          return false;
        }
      }
    },
    preFocus() {
      //预览图片聚焦
      this.contentSet(true);
    },
    lineFeed() {
      //ctrl+enter换行
      this.content = this.$refs.content.innerHTML;
      this.content += '<br><br>';
      setTimeout(() => {
        this.keepLastIndex(this.$refs.content); //光标移动到最后的方法
      }, 5);
    },
    sent() {
      //回车发送信息
      this.contentSet(false);
      let a = this.content;
      this.content = '';
      this.$refs.content.innerHTML = '';
      this.content = '';
      a = a.replace('<div><br></div>', '');
      let imgSrc = a.match(/data:image\/+[^"]*/g);
      if (imgSrc !== null) {
        imgSrc.forEach(item => {
          let file = this.dataToFile(item.toString(), 'image.png');
          this.f.data = file;
          a = a.replace(a.match(/<img[^>]*>/), '$'); //去掉那一截图片
          this.sentImg(this.f); //发图片
        });
      }
      a = a.split('$');
      a.forEach(i => {
        if (i !== '') {
          this.sentText(i);
        }
      });
    },
    sentText(i) {
      //发文本信息
      i = i.replaceAll('<div><br></div>', '');
      if (i === '') {
        return false;
      }
      let target = this.chatList[this.chatSelect].id;
      let chatType = this.chatList[this.chatSelect].is;
      console.log(target, chatType);
      postChatwsMsSend({
        target_type: chatType,
        target: [target],
        msg: {
          type: 'txt',
          msg: i,
        },
        type: '',
        from: cookie(document).get('uid'),
      }).then(res => {
        //发文字接口
        let data = res.data.data.others;
        console.log(data);
        data.chatType = data.chatType === 'chatgroups' ? 'group' : 'contact';
        data.msg = data.msg.msg;
        data.bySelf = true;
        data.time = parseInt(data.timestamp);
        this.$store.commit('updateMsgList', data); //发消息的时候保存该记录
        this.$refs.message.scrollTopToHeight(); //滚动条移最下
        this.$socket.send(JSON.stringify(data)); //发给wss
      });
    },
    sentImg(file) {
      let chatType = this.chatList[this.chatSelect].is;
      let chatId = {};
      chatId.groupid = this.chatList[this.chatSelect].id;
      chatId.name = this.chatList[this.chatSelect].name;
      file.filename = file.data.name;
      let obj = {
        chatType: chatType,
        chatId: chatId,
        file: file,
        callback: data => {
          this.$refs.communiup.value = null;
          this.$refs.message.scrollTopToHeight();
          this.$socket.send(JSON.stringify(data)); //发给wss
        },
      };
      this.sendImgMessage(obj);
    },
    sentFile(file) {
      let chatType = this.chatList[this.chatSelect].is;
      let chatId = {};
      chatId.groupid = this.chatList[this.chatSelect].id;
      chatId.name = this.chatList[this.chatSelect].name;
      file.filename = file.data.name;
      let obj = {
        chatType: chatType,
        chatId: chatId,
        file: file,
        callback: data => {
          this.$refs.communiup.value = null;
          this.$refs.message.scrollTopToHeight();
          this.$socket.send(JSON.stringify(data)); //发给wss
        },
      };
      this.sendFileMessage(obj);
    },
    ku(e) {
      if (e.target.nodeName === 'IMG') {
        //点击图片的方法
        console.log('点击了图片');
      }
    },
    dataToFile(base64Data, fileName) {
      //base64转文件
      let blob = dataURLtoBlob(base64Data);
      let file = blobToFile(blob, fileName);
      return file;
    },
    receivedMsg(msg) {
      console.log('[Communicate Component]  call com onMsg', msg);
      /*[this.chatList[this.chatSelect].groupid]*/
      //修改状态

      /*      window.console.log("onReceivedMessage", message);
      message.status = "sent";//将状态改sent
      window.Vue.$store.commit("updateMessageStatus", message);*/
    },
    renderTime(time) {
      const nowStr = new Date();
      const localStr = time ? new Date(time) : nowStr;
      const localMoment = moment(localStr);
      const localFormat = localMoment.format('MM-DD hh:mm A');
      return localFormat;
    },
    hmTime(time) {
      if (!time) {
        return null;
      } else return time.substr(5, 6);
    },
    showModName() {
      this.modNameShow = !this.modNameShow;
      if (this.modNameShow) {
        this.$nextTick(() => {
          this.$refs.groupnamemod.focus();
        });
      }
    },
    groupnamemodBlur() {
      this.modNameShow = false;
    },
    modGroupName(group_name = this.newGroupName, description = '', group_id = this.chatList[this.chatSelect].id) {
      if (group_name.length > 16) {
        this.newGroupName = this.newGroupName.substr(0, 16);
        this.$alert('群聊名长度不能超过16个', '群组名字过长', { confirmButtonText: '确定', center: true });
        return;
      }
      //修改群名
      let obj = {
        group_name,
        description,
        group_id,
      };
      let sendData = obj;
      sendData.from_name = this.name;
      sendData.type = '改群名';
      sendData.oldName = this.chatList[this.chatSelect].name;
      sendData.status = '改群名';
      this.$store.commit('updateGroupStatus', '改群名');
      let callback = () => {
        this.chatList[this.chatSelect].name = this.newGroupName;
        Message({
          type: 'info',
          message: '修改群名成功',
        });
        this.$socket.send(JSON.stringify(sendData));
      };
      obj.callback = callback;
      this.onUpdataGroupInfo(obj);
      this.modNameShow = false;
    },
    delGroup() {
      //解散群组
      postChatwsGroupDel({
        id: this.chatList[this.chatSelect].id,
      }).then(() => {
        let obj = {};
        obj.type = '解散群组';
        let id = this.chatList[this.chatSelect].id;
        obj.group_id = id;
        obj.from_name = this.name;
        obj.group_name = this.chatList[this.chatSelect].name;
        obj.status = { type: '解散群组', id };
        this.$store.commit('updateGroupStatus', obj.status);
        let arr = this.$store.state.chat.userList.sentMsLis.concat();
        arr.splice(this.chatSelect, 1);
        this.$store.commit('updateUserList', {
          //触发watch
          userList: arr,
          type: 'sentMsLis',
        });
        delete this.$store.state.chat.msgList.group[id];
        this.chatSelect = 0;
        Message({
          type: 'info',
          message: `成功解散群组`,
        });
        this.$socket.send(JSON.stringify(obj));
      });
    },
    getGroupUsers(i) {
      //查找群成员
      if (this.chatList[i].id === '' || this.chatList[i].is === 'users') {
        return false;
      } else {
        postChatwsGroupUserLis({
          group_id: this.chatList[i].id,
        }).then(res => {
          this.groupmember = []
          res.data.data.map(v=>{
            if(Object.keys(v).length>0){
              this.groupmember.push(v)
            }
            
          });
          this.boss = this.groupmember[0].nickname;
        });
      }
    },
  },
  computed: {
    noticeCallMsg() {
      // 监听call组件收到IM 消息
      return this.$store.state.chat.noticeCallMsg;
    },
    groupMsg() {
      //最后一条消息
      return function (id, is) {
        // console.log(typeof id,is);
        if (typeof id == 'undefined') {
          return false;
        }
        let data =
          is === 'chatgroups' ? this.$store.state.chat.msgList.group[id] : this.$store.state.chat.msgList.contact[id];

        if (id.indexOf('s-') === 0) {
          // 云平台账号
          const _data = data.sort((a, b) => a.id - b.id);
          return _data[_data.length - 1];
        } else {
          if (typeof data === 'object') {
            return data[data.length - 1];
          } else {
            return false;
          }
        }
      };
    },
    ml() {
      return this.$store.state.chat.msgList;
    },
  },
  watch: {
    chatList: {
      handler() {
        if (this.chatList[0].id) {
          let customers = this.chatList.filter(v => v.phone);
          if (customers.length > 0) {
            customers = customers.map(v => {
              return {
                ...v,
                isGetting: false,
              };
            });
            const timer = setInterval(() => {
              for (let i = 0; i < customers.length; i++) {
                if (this.loading) return; // 如果 还在加载初始聊天记录，则跳出
                if (customers[i].isGetting) return;

                customers[i].isGetting = true;
                let obj = {};
                obj.chatType = 'users';
                obj.chatId = customers[i].id;
                obj.last_id = 0; // 因为取第一条数据的id，仍然获取所有，所以这里默认获取所有
                obj.canGetLastMsg = true;
                console.log(obj);
                this.$store.commit('updateChatStatus', '获取聊天记录');
                obj.final = () => {
                  this.loading = false;
                };
                obj.getLastMsg = data => {
                  customers[i].isGetting = false;
                  if (data.length > 0) {
                    data.forEach((v, k) => {
                      this.notifyPromise = this.notifyPromise.then(this.$nextTick).then(() => {
                        this.$notify({
                          title: `您有1条来自${v.from}的新消息`,
                          message: `<p>${v.msg.msg}</p>`,
                          dangerouslyUseHTMLString: true,
                        });
                      });
                    });
                  }
                };
                // obj.fail = () => {
                //   Message({
                //     type: 'info',
                //     message: '没有更多聊天记录',
                //   });
                // };
                this.getHistoryMessage(obj);
              }
            }, 1000);

            this.$once('hook:beforeDestroy', () => {
              clearInterval(timer);
            });
          }
        }

      },
      immediate: true,
    },
    content: {
      handler() {},
    },
    '$route.path': {
      handler(val) {
        if (val === '/chat/communicate') {
          if (this.$refs.message === undefined) {
            return;
          } else {
            this.$refs.message.scrollTopToHeight(); //滚动条移最下
          }
        }
        let status = this.$store.state.group.groupStatus;
        if (status === '跳转') {
          this.$store.commit('updateGroupStatus', '');
          this.getChatList(false);
        }
      },
    },
    noticeCallMsg: {
      // 监听call组件收到IM 消息
      handler(msg) {
        this.getChatList(false);
        this.receivedMsg(msg);
      },
    },
    '$store.state.group.groupInviteNotifications': {
      handler(b) {
        if (b) {
          this.$store.state.group.groupInviteNotifications.isShow = false;
          /*this.AgreeJoinGroup();*/
        }
      },
      immediate: true,
    },
    '$store.state.chat.userList.sentMsLis': {
      //只对本端有用
      handler(list, oldList) {
        //监听到变化后更新聊天列表
        let status = this.$store.state.group.groupStatus;
        if (list.length !== 0) {
          this.chatList = list;
          if (status === '') {
            this.chatSelect = 0;
            this.getGroupUsers(0); //获取群成员
          } else if (status === '创建群组') {
            if (this.chatSelect + 1 < this.chatList.length) {
              this.chatSelect++;
            }
            if (this.chatList.length === 1) {
              //创建的第一个群
              this.getGroupUsers(0); //获取群成员
            }
          } else if (status.type === '解散群组') {
            let chat = oldList.filter(item => {
              return item.id === status.id;
            })[0];
            let index = oldList.indexOf(chat);
            if (index <= this.chatSelect && index !== 0) {
              this.chatSelect--;
            } else {
              this.chatSelect = 0;
            }
            this.getGroupUsers(this.chatSelect); //获取群成员
          } else if (status === '邀请好友') {
            this.chatSelect++;
          }
          this.$store.commit('updateGroupStatus', '');
        } else if (list.length === 0) {
          //关闭右边列表
          this.showRight = false;
          //清空聊天记录
          this.$store.commit('updateCurrentMsgList', []);
          if (status.type === '解散群组') {
            this.chatList = [
              {
                is: '', //users或者chatgroups,用于判断是群聊还是单聊
                id: '', //当is为chatgroups时存在
                name: '',
                avatar: '', //头像
              },
            ];
            this.$store.commit('updateGroupStatus', '');
          }
        }
      },
      deep: true,
      immediate: true,
    },
    // chatSelect: {
    //   //点击的时候获取群成员
    //   handler(val) {
    //     let status = this.$store.state.group.groupStatus;
    //     if (this.chatList.length > 0) {
    //       if (this.chatList[val].is === 'chatgroups' && status === '') {
    //         this.getGroupUsers(val);
    //         this.newGroupName = this.chatList[val].name;
    //       }
    //     }
    //   },
    //   immediate: true,
    // },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/chat/communicate';
.picker-block {
  position: absolute;
  bottom: 190px;
  .emoji-mart[data-v-7bc71df8] {
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 420px;
    color: #ffffff !important;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background: #fff;
  }
  .text {
    display: block;
    margin: 0 auto 10px;
    width: 400px;
    resize: none;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 8px;
  }
  .text-place {
    height: 80px;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 8px;
    background: #ddd5d5;
  }
  .text-place p {
    display: flex;
    align-items: center;
    margin: 0;
  }
}
.cropper-block {
  position: fixed;
  top: 0;
  left: 0;
  background-color: burlywood;
  width: 100%;
  height: 100%;
}
</style>
