<template>
  <div class="cover" @click="changeCreateModel()">
    <div class="add-group" @click="$event.stopPropagation()">
      <main>
        <div class="left">
          <div class="search">
            <input type="tel" placeholder="搜索成员..." v-model="memberKeyword" @keyup.enter="memberSearch" />
          </div>
          <div class="display-member-list" @click="ctrl = !ctrl">
            <img :src="openlisticon" alt="" v-show="ctrl" />
            <img :src="closelisticon" alt="" v-show="!ctrl" />
            <span v-if="memberKeyword === ''">全部联系人({{ friendList.length }}人）</span>
            <span v-else>查到联系人({{ resMember.length }}人）</span>
          </div>
          <div class="list">
            <ul class="member-ul" v-show="ctrl" v-if="memberKeyword === ''">
              <li v-for="(item, index) in friendList" :key="index" @click="isSelect(item.id)">
                <div class="head-draw head">{{ lastName(item.nickname) }}</div>
                <span>{{ item.nickname }}</span>
                <input
                  type="checkbox"
                  :value="item.id"
                  v-model="selectList"
                  autocomplate="off"
                  :disabled="disabledList.indexOf(item.id) !== -1"
                  :class="{ 'dis-select': disabledList.indexOf(item.id) !== -1 }"
                />
              </li>
            </ul>
            <ul class="member-ul" v-show="ctrl" v-if="memberKeyword !== ''">
              <li v-for="(item, index) in resMember" :key="index" @click="isSelect(item.id)">
                <div class="head-draw head">{{ lastName(item.nickname) }}</div>
                <span>{{ item.nickname }}</span>
                <input
                  type="checkbox"
                  :value="item.id"
                  v-model="selectList"
                  autocomplate="off"
                  :disabled="disabledList.indexOf(item.id) !== -1"
                  :class="{ 'dis-select': disabledList.indexOf(item.id) !== -1 }"
                />
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="result">已选择了{{ selectMember.length }}个联系人</div>
          <ul class="selected">
            <li v-for="(item, index) in selectMember" :key="index">
              <div class="head-draw head">
                {{ lastName(item.nickname) }}
              </div>
              <span>{{ lastName(item.nickname) }}</span>
              <div class="clean-icon el-icon-circle-close" @click="isSelect(item.id)" />
            </li>
          </ul>
          <div class="button">
            <button class="ok" @click="postCreateGroup">确定</button>
            <button class="cancel" @click="changeCreateModel()">取消</button>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { postChatwsTeam, postChatwsGroupUsersNew } from '@/service/help';
import cookie from '@/utils/cookie';
import { mapActions } from 'vuex';
import { Message } from 'element-ui';
import my from '@/components/task/disk/my';
export default {
  name: 'addGroup',
  data() {
    return {
      ctrl: true,
      openlisticon: require('@/assets/image/progress/addTask/openlist.png'),
      closelisticon: require('@/assets/image/progress/addTask/closelist.png'),
      friendList: [], //通过请求查询自己的所以好友
      selectList: [], //已选择打钩的好友
      disabledList: [], //通过群组进来时原先的群好友并设置禁选;
      memberKeyword: '',
    };
  },
  created() {
    postChatwsTeam({ keyword: '' }).then(res => {
      this.friendList = res.data.data;
    });
    let tf = typeof this.friend;
    console.log('tf', tf, this.friend);
    if (tf === 'string') {
      let myId = cookie(document).get('uid');
      if (this.friend !== myId) {
        //单聊的加号来的
        this.selectList.push(this.friend);
      }
      this.selectList.push(myId);
      this.disabledList.push(myId);
    } else if (tf === 'object') {
      this.friend.forEach(item => {
        if (typeof item.id !== 'undefined') {
          this.selectList.push(item.id);
        }
      });
      this.disabledList = Object.assign([], this.selectList);
    }
  },
  props: ['friend', 'id', 'groupname'],
  methods: {
    ...mapActions(['onCreateGroup']),
    postCreateGroup() {
      //创建群组
      let userids = [];
      let usernames = [];
      this.selectMember.forEach(item => {
        usernames.push(item.nickname);
      });
      this.selectList.forEach(item => {
        //过滤已经在群里的成员
        if (this.disabledList.indexOf(item) === -1) {
          let data = parseInt(item);
          userids.push(data);
        }
      });
      if (typeof this.friend === 'object') {
        //邀请好友
        postChatwsGroupUsersNew({
          id: this.id,
          uid: userids,
        }).then(res => {
          let sendData = {};
          sendData.type = '邀请好友进群';
          sendData.from_name = cookie(document).get('name');
          sendData.group_name = this.groupname;
          sendData.friends = usernames;
          sendData.status = '邀请好友';
          this.$store.commit('updateGroupStatus', sendData.status); //更新群状态
          Message({
            type: 'info',
            message: '邀请成功',
          });
          this.$socket.send(JSON.stringify(sendData));
          console.log(res);
        });
      } else {
        //否则创建群聊
        let callback = () => {
          let sendData = {};
          sendData.type = '创建群聊';
          sendData.from_name = cookie(document).get('name');
          sendData.friends = usernames;
          sendData.status = '创建群组';
          this.$store.commit('updateGroupStatus', sendData.status); //更新群状态
          Message({
            type: 'info',
            message: '创建成功',
          });
          this.$socket.send(JSON.stringify(sendData));
        };
        let name = cookie(document).get('name') + '、' + usernames[0] + '、' + usernames[1];
        if (name.length > 16) {
          name = '群聊';
        }
        this.onCreateGroup({
          groupname: name,
          members: userids,
          callback,
        });
      }
      this.changeCreateModel();
    },
    changeCreateModel() {
      this.$emit('groupClose');
    },
    memberSearch() {},
    isSelect(id) {
      if (this.disabledList.indexOf(id) === -1) {
        let i = this.selectList.indexOf(id);
        if (i !== -1) {
          this.selectList.splice(i, 1);
        } else {
          this.selectList.push(id);
        }
      }
    },
    lastName(name) {
      if (name) return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
  },
  computed: {
    resMember() {
      return this.friendList.filter(
        function(item) {
          this.memberKey = ''; //取消选中
          return item.nickname.indexOf(this.memberKeyword) >= 0;
        }.bind(this),
      );
    },
    selectMember() {
      let data = this.friendList.filter(
        function(item) {
          return this.selectList.indexOf(item.id) >= 0;
        }.bind(this),
      );
      //还需要过滤一次
      data = data.filter(
        function(item) {
          return this.disabledList.indexOf(item.id) === -1;
        }.bind(this),
      );
      // if (typeof this.friend === 'object') {
      //
      // }
      return data;
    },
  },
};
</script>

<style scoped lang="less">
@import 'addGroup.less';
</style>
