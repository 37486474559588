<template>
  <div class="message">
    <div
      class="messagebox-content"
      ref="content"
      @scroll="handleScroll()"
      v-loading.lock="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      :data="msgList"
    >
      <div
        class="message-group"
        v-for="item in msgList"
        :class="{
          'message-right': item.bySelf,
          'message-img': item.type === 'img',
          'message-file': item.type === 'file',
        }"
        :style="{ float: item.bySelf ? 'right' : 'left' }"
        :key="item.mid"
      >
        <div class="head-draw head" :class="{ 'head-right': item.bySelf, 'head-left': !item.bySelf }">
          {{ lastName(item.user.from_name) }}
        </div>
        <h4 :style="{ 'text-align': item.bySelf ? 'right' : 'left' }" style="margin:0" v-if="!item.bySelf">
          {{ item.user.from_name }}
        </h4>
        <!-- <h4 :style="{ 'text-align': item.bySelf ? 'right' : 'left' }" style="margin:0" v-if="!item.bySelf">
          {{ item.from }}
        </h4> -->
        <img v-if="item.type === 'img'" :src="item.msg" width="240px" />
        <div v-else-if="item.type === 'file'" class="msg-file" style="text-align:left">
          <div class="file-name">
            <h3>{{ item.filename }}</h3>
            <img
              src="../../../assets/image/icon_disk/icon_wp_word_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 1"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_excel_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 2"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_ppt_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 3"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_pdf_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 4"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_text_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 5"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_music_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 6"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_video_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 7"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_yswj_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 8"
            />
            <img
              src="../../../assets/image/icon_disk/icon_wp_yswj_70@2x.png"
              width="45px"
              v-if="typeFile(item.filename) === 999"
            />
          </div>
          <span>{{ readablizeBytes(item.file_length) }}</span>
          <a :href="item.msg" :download="item.filename">点击下载</a>
        </div>
        <div v-else class="msg-text" :style="{ 'text-align': item.bySelf ? 'right' : 'left' }">
          <div style="text-align:left" v-html="renderTxt(item.msg)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { postChatwsMsInfo } from '@/service/help';
import cookie from '@/utils/cookie';
import { typeFile } from '@/utils/utils';
import { Message } from 'element-ui';
export default {
  name: 'message',
  data() {
    return {
      i: 0, //滚动条相关
      scrollHeight: 0,
      loading: false,
      timer: null,
    };
  },
  props: ['id', 'type'], //id 和 类型is
  computed: {
    msgList() {
      let type = this.type === 'chatgroups' ? 'group' : 'contact';
      let data = this.$store.state.chat.msgList[type][this.id];
      if (this.id.indexOf('s-1') !== -1 && data) {
        return data.sort((a, b) => a.id - b.id);
      }
      //   typeof this.$store.state.chat.currentMsgs === 'undefined'
      //     ? this.$store.state.chat.msgList['contact'][this.id]
      //     : this.$store.state.chat.currentMsgs; //做个判断弥补第一次获取单聊记录失败的bug
      return data;
    },
    msg() {
      let lis = this.$store.state.chat.msgList;
      return lis;
    },
  },
  created() {
    console.log('message组件');
    setTimeout(() => {
      this.getCurrentMsg(() => {
        this.scrollTopToHeight();
      });
      postChatwsMsInfo({
        target: this.id,
        target_type: 'chatgroups',
        last_id: '9999',
      }).then(res => {
        this.msgByHui = res.data.data;
      });
    }, 500);
  },
  mounted() {
    const timer = setInterval(() => {
      if (this.loading) return; // 如果 还在加载初始聊天记录，则跳出
      if (this.id.indexOf('s-') === -1) {
        // 不是云平台的聊天窗，则跳出
        clearInterval(this.timer);
        return;
      }
      let obj = {};
      obj.chatType = this.type;
      obj.chatId = this.id;
      obj.last_id = 0; // 因为取第一条数据的id，仍然获取所有，所以这里默认获取所有
      obj.isUpdate = true;
      this.$store.commit('updateChatStatus', '获取聊天记录');
      obj.final = () => {
        this.loading = false;
      };
      obj.fail = () => {
        Message({
          type: 'info',
          message: '没有更多聊天记录',
        });
      };
      this.getHistoryMessage(obj);
    }, 1000);

    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer);
    });
  },
  methods: {
    ...mapActions(['onGetGroupUserList', 'onGetCurrentChatObjMsg', 'getHistoryMessage']),
    getCurrentMsg(callback) {
      //获取当前聊天记录
      let type = this.type === 'chatgroups' ? 'group' : 'contact';
      let id = this.id;
      this.onGetCurrentChatObjMsg({
        type: type,
        id: id,
        callback: callback ? callback : () => {},
      });
    },
    handleScroll() {
      console.log(this.$refs['content'].scrollHeight);
      var scrollTop = this.$refs['content'].scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scroll; //this.i初始化为0
      if (scroll < 0 && this.$refs['content'].scrollTop < 0.5) {
        this.$refs['content'].scrollTop = 0;
        this.loading = true;
        let obj = {};
        obj.chatType = this.type;
        obj.chatId = this.id;
        obj.last_id = parseInt(this.msgList[0].id); //取第一条数据的id
        this.$store.commit('updateChatStatus', '获取聊天记录');
        obj.final = () => {
          this.loading = false;
        };
        obj.fail = () => {
          Message({
            type: 'info',
            message: '没有更多聊天记录',
          });
        };
        this.getHistoryMessage(obj);
      }
    },
    scrollTopToHeight() {
      if (typeof this.$refs['content'] !== 'undefined') {
        this.$nextTick(() => {
          this.i = 0;
          this.scrollHeight = this.$refs['content'].scrollTop = this.$refs['content'].scrollHeight; //滚动条自动滑动
        });
      }
    },
    renderTime(time) {
      const nowStr = null;
      const localStr = time ? new Date(time) : nowStr;
      if (localStr === nowStr) {
        return false;
      }
      const localMoment = moment(localStr);
      const localFormat = localMoment.format('MM-DD hh:mm A');
      return localFormat;
    },
    renderTxt(txt = '') {
      let rnTxt = [];
      let start = 0;
      rnTxt.push(txt.substring(start, txt.length));
      return rnTxt.toString().replace(/,/g, '');
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
    typeFile(filename) {
      return typeFile(filename);
    },
    readablizeBytes(value) {
      let s = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
      let e = Math.floor(Math.log(value) / Math.log(1024));
      if (e === -Infinity) {
        return '0 Bytes';
      }
      return (value / Math.pow(1024, Math.floor(e))).toFixed(2) + ' ' + s[e];
    },
  },
  watch: {
    msgList: {
      handler() {
        if (this.$store.state.chat.chatStatus === '获取历史记录') {
          this.$nextTick(() => {
            let h = this.scrollHeight;
            this.scrollHeight = this.$refs['content'].scrollHeight;
            let diff = this.scrollHeight - h;
            this.$refs['content'].scrollTop = diff;
          });
          this.$store.commit('updateChatStatus', '');
        }
      },
    },
    '$store.state.chat.noticeCallMsg': {
      handler() {
        this.getCurrentMsg(() => {
          this.scrollTopToHeight();
        });
      },
      immediate: true,
      deep: true,
    },
    id: {
      handler(newVal) {
        if (typeof newVal !== 'undefined') {
          this.getCurrentMsg(() => {
            this.scrollTopToHeight();
          });
        }
      },
      deep: true,
    },
    account: {
      handler(newVal) {
        //第一次获取单聊记录的时候为空，时机问题
        if (typeof newVal !== 'undefined') {
          this.getCurrentMsg(() => {
            this.scrollTopToHeight();
          });
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="less">
.message {
  padding: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  .messagebox-content {
    width: 100%;
    height: 100%;
    background: #fbfbfd;
    padding: 0px 82px 0px 82px;
    overflow: hidden scroll;
    .message-group {
      margin-top: 40px;
      position: relative;
      background-color: #ffffff;
      border-radius: 0px 10px 10px 10px;
      border: 1px solid #e8ecef;
      padding: 10px;
      font-size: 14px;
      line-height: 1.5;
      max-width: 100%;
      clear: both;
      .head {
        position: absolute;
      }
      .head-left {
        left: -50px;
        top: -30px;
      }
      .head-right {
        right: -50px;
        top: -10px;
      }
      h4 {
        font-family: 'PingFang SC';
        white-space: nowrap; //不换行撑大父div
        color: #334681;
        position: absolute;
        left: 0;
        top: -25px;
      }
      .msg-file {
        width: 200px;
        height: 80px;
        background-color: white;
        .file-name {
          overflow: hidden;
          margin-bottom: 10px;
          h3 {
            word-break: break-all;
            word-wrap: break-word;
            width: 150px;
            height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            display: inline-block;
          }
          img {
            float: right;
          }
        }
        text-align: center;
      }
      .msg-text {
        color: #334681;
      }
    }
    .message-right {
      margin-top: 20px;
      border-radius: 10px 0px 10px 10px;
      background: #13bfff;
      .msg-text {
        color: #fff;
      }
    }
    .message-img {
      //图片的情况
      background-color: #fff;
      padding: 0px;
      img {
        margin-bottom: -5px;
      }
    }
    .message-file {
      //文件的情况
      background-color: #fff;
      padding: 10px;
    }
  }
}
</style>
